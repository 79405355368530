import Vue from 'vue';
import App from './App.vue';

// VUEX - https://vuex.vuejs.org/
import store from '@/store';

// VUE-ROUTER - https://router.vuejs.org/
import router from './router';

import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
// import './plugins/vuetify';

// PLUGINS
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import VueAnalytics from 'vue-analytics'

import './registerServiceWorker';
// import 'vuetify/dist/vuetify.min.css';

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/formatDate'
import './filters/myFilters'

import VueFbCustomerChat from 'vue-fb-customer-chat';
 
Vue.use(VueFbCustomerChat, {
  page_id: "104411568643136", // 輸入 Facebook Page ID,
  theme_color: '#1976D2', // 設定messenger顏色
  locale: 'zh_TW', // 設定語言
  greeting_dialog_display: 'hide',
})

// STYLES
// main theme scss
import './assets/scss/theme.scss';

Vue.config.productionTip = false;
Vue.use(VueAnalytics, {
  id: 'UA-237439494-1',
  router
})

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-60PMNX517D" }
}, router);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// export default new Vue({
//   vuetify,
//   router,
//   store,
//   render: (h) => h(App)
// }).$mount('#app')
