import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import * as directives from 'vuetify/lib/directives'
import config from '../configs'

// import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify, {
  directives
})

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    dark: config.theme.globalTheme === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light
    }
  },
});


// Vue.use(Vuetify, {
//   iconfont: 'md',
//   // theme: {
//   //   themes: {
//   //       light: {
//   //           primary: '#e20074',
//   //           secondary: '#6c757d',
//   //           accent: '#3ea2fb',
//   //           error: '#dc3545',
//   //           petrol: '#17a499',
//   //           background: '#17a499',
//   //       }
//   //   },
//   //   options: {
//   //       customProperties: true
//   //   },
//   // },
//   // theme: {
//     // primary: '1A73E8',
//     // secondary: colors.red.lighten4, // #FFCDD2
//     // accent: colors.indigo.base // #3F51B5
//   // }
//   // breakpoint: {
//   //   thresholds: {
//   //     xs: 600,
//   //     sm: 960,
//   //     md: 1280,
//   //     lg: 19200,
//   //   },
//   //   scrollbarWidth: 16
//   // }
// });
