import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

import ViewRoot from "@/views/Root.vue"
import ViewToolBar from "@/views/ToolBar.vue"

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash, offset: {x: 0, y: 240} };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '',
      component: () => import('./views/Root.vue'),
      children: [
        {
          path: '',
          component: () => import('./views/ToolBar.vue'),
          children: [
            // {path: '/home', component: () => import('./views/Home.vue'),},
            // {path: '/news', component: () => import('./views/News.vue'),},
            // {path: '/plan', component: () => import('./views/main/Plan.vue'),},
            // {path: '/plan/twd_stable', component: () => import('./views/main/plan/twd_stable.vue'),},
            // {path: '/plan/usdt_stable', component: () => import('./views/main/plan/usdt_stable.vue'),},
            // {path: '/plan/usdt_high', component: () => import('./views/main/plan/usdt_high.vue'),},
            // {path: '/plan/usdt_active', component: () => import('./views/main/plan/usdt_active.vue'),},
            // {path: '/purchase', component: () => import('./views/main/Purchase.vue'),},
            // {path: '/purchaseSchedule', component: () => import('./views/main/PurchaseSchedule.vue'),},
            // {path: '/dashboard', component: () => import('./views/main/Dashboard.vue'),},
            // {path: '/status', component: () => import('./views/main/Status.vue'),},
            // {path: '/dashboardEnded', component: () => import('./views/main/DashboardEnded.vue'),},
            // {path: '/faq', component: () => import('./views/main/about/FAQ.vue'),},
            // {path: '/how-to-purchase', component: () => import('./views/main/about/HowToPurchase.vue'),},
            // {path: '/how-to-redeem', component: () => import('./views/main/about/HowToRedeem.vue'),},
            // {path: '/report/202109', component: () => import('./views/main/report/202109_report.vue'),},
            // {path: '/bonus', component: () => import('./views/main/profile/Bonus.vue'),},
            // {path: '/account', component: () => import('./views/main/profile/Account.vue'),},
            // {path: '/account/kyc', component: () => import('./views/main/profile/KYC.vue'),},
            // {path: '/account/edit', component: () => import('./views/main/profile/UserProfileEdit.vue'),},
            // {path: '/account/password', component: () => import('./views/main/profile/UserProfileEditPassword.vue'),},
            // {path: '/admin/userEdit', component: () => import('./views/main/admin/EditUser.vue'),},
            // {path: '/admin/dashboard', component: () => import('./views/main/DashboardAdmin.vue'),},
            // {path: '/admin/createUser', component: () => import('./views/main/admin/CreateUser.vue'),},
            // {path: '/test', component: () => import('./views/Test.vue'),},
          ]
        },
      ]
    },
    {
      path: '',
      component: () => import('./views/Root.vue'),
      children: [
        {
          path: '',
          component: () => import('./views/DefaultLayout.vue'),
          children: [
            {path: '/home', component: () => import('./views/Home2.vue'),},
            {path: '/login', component: () => import('./views/Login.vue'),},
            {path: '/register', component: () => import('./views/UserCreate.vue'),},            
            {path: '/verify-email', component: () => import('./views/EmailVerification.vue'),},
            {path: '/email-block', component: () => import('./views/EmailBlock.vue'),},
            {path: '/news', component: () => import('./views/News2.vue'),},
            // {path: '/news/20210716', component: () => import('./views/news/20210716_invitation_bonus.vue'),},
            {path: '/news/20211217', component: () => import('./views/news/20211217_invite_bonus.vue'),},
            {path: '/news/20211224', component: () => import('./views/news/20211224_christmas_airdrop.vue'),},
            {path: '/news/20220521', component: () => import('./views/news/20220521_ust_depeg.vue'),},
            {path: '/news/20220531', component: () => import('./views/news/20220531_luna_airdrop.vue'),},
            {path: '/news/20220605', component: () => import('./views/news/20220605_usdd_plan.vue'),},
            {path: '/news/20220821_invite_bonus', component: () => import('./views/news/20220821_invite_bonus.vue'),},
            {path: '/news/20220831_token_plans', component: () => import('./views/news/20220831_token_plans.vue'),},
            {path: '/news/20221110_FTX_incident', component: () => import('./views/news/20221110_FTX_incident.vue'),},
            {path: '/news/20221111_Steaker_incident', component: () => import('./views/news/20221111_Steaker_incident.vue'),},
            {path: '/news/20221130_terminate_all_plans', component: () => import('./views/news/20221130_terminate_all_plans.vue'),},
            {path: '/news/20230313_pause_user_create', component: () => import('./views/news/20230313_pause_user_create.vue'),},
            
            {path: '/report', component: () => import('./views/Report.vue'),},
            {path: '/report/202109', component: () => import('./views/report/202109_report.vue'),},
            {path: '/report/202110', component: () => import('./views/report/202110_report.vue'),},
            {path: '/report/202111', component: () => import('./views/report/202111_report.vue'),},
            {path: '/report/202112', component: () => import('./views/report/202112_report.vue'),},
            {path: '/report/202201', component: () => import('./views/report/202201_report.vue'),},
            {path: '/report/202202', component: () => import('./views/report/202202_report.vue'),},
            {path: '/report/202203', component: () => import('./views/report/202203_report.vue'),},
            {path: '/report/202204', component: () => import('./views/report/202204_report.vue'),},
            {path: '/report/202205', component: () => import('./views/report/202205_report.vue'),},
            {path: '/report/202206', component: () => import('./views/report/202206_report.vue'),},
            {path: '/report/202207', component: () => import('./views/report/202207_report.vue'),},
            {path: '/report/202208', component: () => import('./views/report/202208_report.vue'),},
            {path: '/report/202209', component: () => import('./views/report/202209_report.vue'),},
            
            {path: '/ust', component: () => import('./views/about/USTIncident.vue'),},
            {path: '/ftx-incident', component: () => import('./views/about/FTXIncident.vue'),},
            // {path: '/risk', component: () => import('./views/Risk.vue'),},
            {path: '/plan', component: () => import('./views/Plan2.vue'),},

            {path: '/plan/usdt_sun', component: () => import('./views/plan/usd_sun.vue'),},
            {path: '/plan/usdt_meteor', component: () => import('./views/plan/usd_meteor.vue'),},

            {path: '/plan/usdt_fixed', component: () => import('./views/plan/usd_fixed.vue'),},
            {path: '/plan/usdt_stable', component: () => import('./views/plan/usd_stable.vue'),},
            {path: '/plan/usdt_high', component: () => import('./views/plan/usd_high.vue'),},
            {path: '/plan/usdt_active', component: () => import('./views/plan/usd_active.vue'),},
            {path: '/plan/usdd_stable', component: () => import('./views/plan/usdd_stable.vue'),},
            {path: '/plan/usdt_star', component: () => import('./views/plan/usdt_star.vue'),},
            {path: '/plan/btc_stable', component: () => import('./views/plan/btc_stable.vue'),},
            {path: '/plan/eth_stable', component: () => import('./views/plan/eth_stable.vue'),},
            {path: '/plan/bnb_stable', component: () => import('./views/plan/bnb_stable.vue'),},

            {path: '/purchase', component: () => import('./views/Purchase2.vue'),},
            {path: '/purchase/usdt_fixed', component: () => import('./views/purchase_page/usdt_fixed.vue'),},
            {path: '/purchase/usdt_stable', component: () => import('./views/purchase_page/usdt_stable.vue'),},
            {path: '/purchase/usdt_high', component: () => import('./views/purchase_page/usdt_high.vue'),},
            {path: '/purchase/usdt_active', component: () => import('./views/purchase_page/usdt_active.vue'),},
            {path: '/purchaseSchedule', component: () => import('./views/PurchaseSchedule.vue'),},
            {path: '/dashboard', component: () => import('./views/Dashboard2.vue'),},
            {path: '/status', component: () => import('./views/Status2.vue'),},
            {path: '/dashboardEnded', component: () => import('./views/DashboardEnded2.vue'),},
            {path: '/dashboardDetailed', component: () => import('./views/DashboardDetailed.vue'),},
            {path: '/dashboardAdmin', component: () => import('./views/DashboardAdmin2.vue'),},
            {path: '/faq', component: () => import('./views/about/FAQ2.vue'),},
            // {path: '/faq', component: () => import('./views/about/FAQ3.vue'),},
            {path: '/about', component: () => import('./views/about/AboutRabbit.vue'),},
            {path: '/contact', component: () => import('./views/about/Contact.vue'),},
            // {path: '/about', component: () => import('./views/NotFoundPage.vue'),},
            {path: '/how-to-purchase', component: () => import('./views/about/HowToPurchase2.vue'),},
            {path: '/how-to-swap', component: () => import('./views/about/HowToSwap.vue'),},
            {path: '/how-to-redeem', component: () => import('./views/about/HowToRedeem2.vue'),},
            {path: '/how-to-app', component: () => import('./views/about/HowToApp.vue'),},
            {path: '/bonus', component: () => import('./views/Bonus2.vue'),},
            {path: '/account', component: () => import('./views/profile/Account.vue'),},
            {path: '/account/kyc', component: () => import('./views/profile/KYC.vue'),},
            {path: '/account/otp', component: () => import('./views/profile/OTP.vue'),},
            {path: '/account/edit', component: () => import('./views/profile/UserProfileEdit.vue'),},
            {path: '/account/password', component: () => import('./views/profile/UserProfileEditPassword.vue'),},
            {path: '/account/whitelist', component: () => import('./views/profile/Whitelist.vue'),},
            {path: '/wallet', component: () => import('./views/profile/Wallet.vue'),},
            {path: '/walletDeposit', component: () => import('./views/profile/WalletDeposit.vue'),},
            {path: '/walletWithdrawal', component: () => import('./views/profile/WalletWithdrawal.vue'),},
            {path: '/admin/users', component: () => import('./views/main/admin/AdminUsers.vue'),},
            {path: '/login', component: () => import('./views/Login.vue'),},
            {path: '/createUser', component: () => import('./views/UserCreate.vue'),},
            {path: '/recoverPassword', component: () => import('./views/PasswordRecovery.vue'),},
            {path: '/*', redirect: '/home',},
          ]
        },
      ]
    },
    // {
    //   path: '',
    //   component: () => import('./views/Root.vue'),
    //   children: [
    //     {
    //       path: '',
    //       component: () => import('./views/ToolBar.vue'),
    //       children: [
    //         // {path: '/login', component: () => import('./views/Login.vue'),},
    //         // {path: '/createUser', component: () => import('./views/UserCreate.vue'),},
    //         // {path: '/recoverPassword', component: () => import('./views/PasswordRecovery.vue'),},
    //         // {path: '/*', redirect: '/home',},
    //       ]
    //     },
    //   ]
    // },
  ],
  // routes: [
  //   {
  //     path: '/',
  //     component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
  //     children: [
  //       {
  //         path: 'login',
  //         // route level code-splitting
  //         // this generates a separate chunk (about.[hash].js) for this route
  //         // which is lazy-loaded when the route is visited.
  //         component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
  //       },
  //       {
  //         path: 'create-user',
  //         component: () => import(/* webpackChunkName: "recover-password" */ './views/UserCreate.vue'),
  //       },
  //       {
  //         path: 'recover-password',
  //         component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
  //       },
  //       {
  //         path: 'reset-password',
  //         component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
  //       },
  //       {
  //         path: 'main',
  //         component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
  //         children: [
  //           {
  //             path: 'home',
  //             component: () => import(/* webpackChunkName: "main-home" */ './views/Home.vue'),
  //           },
  //           {
  //             path: 'news',
  //             component: () => import(/* webpackChunkName: "main-home" */ './views/News.vue'),
  //           },
  //           {
  //             path: 'test',
  //             component: () => import(/* webpackChunkName: "main-home" */ './views/Test.vue'),
  //           },
  //           {
  //             path: 'status',
  //             component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Status.vue'),
  //           },
  //           {
  //             path: 'dashboard',
  //             component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
  //           },
  //           {
  //             path: 'dashboardEnded',
  //             component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/DashboardEnded.vue'),
  //           },
  //           {
  //             path: 'dashboardAdmin',
  //             component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/DashboardAdmin.vue'),
  //           },
  //           {
  //             path: 'plan',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/Plan.vue'),
  //           },
  //           {
  //             path: 'plan/usdt_stable',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/plan/usdt_stable.vue'),
  //           },
  //           {
  //             path: 'plan/usdt_high',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/plan/usdt_high.vue'),
  //           },
  //           {
  //             path: 'plan/eth_stable',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/plan/eth_stable.vue'),
  //           },
  //           {
  //             path: 'plan/twd_stable',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/plan/twd_stable.vue'),
  //           },
  //           {
  //             path: 'plan/twd_newbie',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/plan/twd_newbie.vue'),
  //           },
  //           {
  //             path: 'faq',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/FAQ.vue'),
  //           },
  //           {
  //             path: 'purchase',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/Purchase.vue'),
  //           },
  //           {
  //             path: 'purchaseSchedule',
  //             component: () => import(/* webpackChunkName: "main-plan" */ './views/main/PurchaseSchedule.vue'),
  //           },
  //           {
  //             path: 'profile',
  //             component: RouterComponent,
  //             redirect: 'profile/view',
  //             children: [
  //               {
  //                 path: 'bonus',
  //                 component: () => import(
  //                   /* webpackChunkName: "main-profile" */ './views/main/profile/Bonus.vue'),
  //               },
  //               {
  //                 path: 'account',
  //                 component: () => import(
  //                   /* webpackChunkName: "main-profile" */ './views/main/profile/Account.vue'),
  //               },
  //               {
  //                 path: 'view',
  //                 component: () => import(
  //                   /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
  //               },
  //               {
  //                 path: 'edit',
  //                 component: () => import(
  //                   /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
  //               },
  //               {
  //                 path: 'password',
  //                 component: () => import(
  //                   /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
  //               },
  //             ],
  //           },
  //           {
  //             path: 'admin',
  //             component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
  //             redirect: 'admin/users/all',
  //             children: [
  //               {
  //                 path: 'users',
  //                 redirect: 'users/all',
  //               },
  //               {
  //                 path: 'users/all',
  //                 component: () => import(
  //                   /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
  //               },
  //               {
  //                 path: 'users/edit/:id',
  //                 name: 'main-admin-users-edit',
  //                 component: () => import(
  //                   /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
  //               },
  //               {
  //                 path: 'users/create',
  //                 name: 'main-admin-users-create',
  //                 component: () => import(
  //                   /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     path: '/*', redirect: '/',
  //   },
  // ],
});
