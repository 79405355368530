import { api } from '@/api';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitAddNotification,
    commitRemoveNotification,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetToken,
    commitSetUserProfile,
    commitSetUserWallet,
    commitSetUserTransactions,
    commitSetUserInwardTransactions,
    commitSetUserOutwardTransactions,
    commitSetUserWhitelistAddresses,
    commitSetPositions,
    commitSetUserItems,
    commitSetUserItemsOngoing,
    commitSetUserItemsPending,
    commitSetUserItemsEnded,
    commitSetUserItemDetail,
    commitSetUserCreate,
    commitSendKYC,
    commitGenerateOtp,
    commitSetOtpQrcode,
    commitSetOtpInfo,
    commitSetupOTP,
    commitSetItemCreate,
    commitSetItemAddressUpdate,
    commitSetItemCancelPurchase,
    commitSetItemExtend,
    commitSetItemProposeWithdrawal,
    commitSetWalletProposeWithdrawal,
    commitSetConstants,
    commitSetGraphData,
    commitSetInvitedUsers
} from './mutations';
import { AppNotification, MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionLogIn(context: MainContext, payload: { username: string; password: string }) {
        try {
            const response = await api.logInGetToken(payload.username, payload.password);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Logged in', color: 'success' });
                router.push('/dashboard');
            } else {
                await dispatchLogOut(context);
            }
        } catch (error) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
            if (error.response!.data.detail === "Incorrect email or password") {
                commitAddNotification(context, { content: 'Email 或密碼不正確', color: 'error' });
            } else if (error.response!.data.detail === "Email not verified") {
                commitAddNotification(context, { content: '尚未通過 Email 驗證', color: 'error' });
                router.push('/email-block');
            } else if (error.response!.data.detail === "Inactive user") {
                commitAddNotification(context, { content: '帳戶已鎖定', color: 'error' });
            } else if (error.response!.data.detail === "Invalid OTP") {
                commitAddNotification(context, { content: 'Google 驗證碼錯誤', color: 'error' });
            }
        }
    },
    // my edit
    async actionUserCreate(context: MainContext, payload) {
        const loadingNotification = { content: '註冊中', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUserOpen(payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserCreate(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '帳號註冊成功！請查看您的 Email 信箱！', color: 'success' });
            router.push('/login');
        } catch (error) {
            if (error.response!.status === 400) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Email 已被使用', color: 'error' });
            } else if (error.response!.status === 422) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Please enter valid email address', color: 'error' });
            }
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSendKYC(context: MainContext, payload) {
      const loadingNotification = { content: '傳送中', showProgress: true };
      try {
          commitAddNotification(context, loadingNotification);
          const response = (await Promise.all([
              api.sendKYC(context.state.token, payload),
              await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
          ]))[0];
          commitSendKYC(context, response.data);
          commitRemoveNotification(context, loadingNotification);
          commitAddNotification(context, { content: '手機驗證通過！', color: 'success' });
          router.push('/account');
      } catch (error) {
          if (error.response!.status === 400) {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Email 已被使用', color: 'error' });
          } else if (error.response!.status === 422) {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Please enter valid email address', color: 'error' });
          } else if (error.response!.status === 406) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '驗證碼錯誤', color: 'error' });
          } 
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGenerateOtp(context: MainContext) {
      try {
        const response = await api.generateOtp(context.state.token);
        if (response) {
            commitGenerateOtp(context, response.data);
        }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetOtpQrcode(context: MainContext) {
      try {
        const response = await api.getOtpQrcode(context.state.token);
        if (response.data) {
            commitSetOtpQrcode(context, response.data);
        }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetOtpInfo(context: MainContext) {
      try {
        const response = await api.getOtpInfo(context.state.token);
        if (response) {
            commitSetOtpInfo(context, response.data);
        }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionSetupOTP(context: MainContext, payload) {
      const loadingNotification = { content: '傳送中', showProgress: true };
      try {
        console.log(payload);
          commitAddNotification(context, loadingNotification);
          const response = (await Promise.all([
              api.setupOtp(context.state.token, payload),
              await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
          ]))[0];
          commitSetupOTP(context, response.data);
          commitRemoveNotification(context, loadingNotification);
          commitAddNotification(context, { content: 'Google 雙重驗證已開啟', color: 'success' });
          
          // update user profile
          try {
              const response = await api.getMe(context.state.token);
              if (response.data) {
                  commitSetUserProfile(context, response.data);
              }
          } catch (error) {
              await dispatchCheckApiError(context, error);
          }
      } catch (error) {
          if (error.response!.status === 400) {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: '400 Error', color: 'error' });
          } else if (error.response!.status === 406) {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Google 驗證碼錯誤', color: 'error' });
          } else if (error.response!.status === 422) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '422 Error', color: 'error' });
          }
          await dispatchCheckApiError(context, error);
      }
    },
    async actionItemCreate(context: MainContext, payload) {
        const loadingNotification = { content: '申購中', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createItem(context.state.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetItemCreate(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '申購成功！我們已將合約寄至您的 Email，信件有可能被歸類垃圾信件，請多加注意！', color: 'success' });
            router.push('/purchaseSchedule');
        } catch (error) {
            if (error.response!.status === 400) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Bad request 400', color: 'error' });
            } else if (error.response!.status === 422) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Invalid 422', color: 'error' });
            } else if (error.response!.data.detail === "Not authorized to purchase active plan.") {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: '您無法申購此方案', color: 'error' });
            } else if (error.response!.data.detail === "Anti-DDOS.") {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: '您申購太多筆方案了！', color: 'error' });
            } else if (error.response!.status === 406) {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Error code: 406', color: 'error' });
            } 
            await dispatchCheckApiError(context, error);
        }
    },
    async actionItemAddressUpdate(context: MainContext, payload: {itemId: number, address: string, withdrawal_blockchain_type: string, otp_key: string}) {
        const loadingNotification = { content: '修改中', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateItemAddress(context.state.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetItemAddressUpdate(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '提款地址修改成功！', color: 'success' });
            // router.push('/dashboard');
            dispatchGetUserItemDetail(context, {itemId: payload.itemId});
        } catch (error) {
            if (error.response!.status === 400) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Bad request', color: 'error' });
            } else if (error.response!.data.detail === "Incorrect OTP public key.") {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Google 驗證碼錯誤', color: 'error' });
            } else if (error.response!.status === 422) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Error 422 Invalid', color: 'error' });
            }
            await dispatchCheckApiError(context, error);
        }
    },
    async actionItemCancelPurchase(context: MainContext, payload: {itemId: number}) {
        const loadingNotification = { content: '取消中', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.cancelItemPurchase(context.state.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetItemCancelPurchase(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '申購方案已取消', color: 'success' });
            
            // update user profile
            try {
              const response = await api.getItemsPending(context.state.token);
              if (response.data) {
                  commitSetUserItemsPending(context, response.data);
              }
            } catch (error) {
                await dispatchCheckApiError(context, error);
            }
        } catch (error) {
            if (error.response!.status === 400) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Bad request', color: 'error' });
            } else if (error.response!.status === 406) {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: '已付款的方案無法取消', color: 'error' });
            } else if (error.response!.status === 405) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: '405 Error', color: 'error' });
            }
            await dispatchCheckApiError(context, error);
        }
    },
    async actionItemExtend(context: MainContext, payload: {itemId: number, otp_key: string}) {
      const loadingNotification = { content: '續約中', showProgress: true };
      try {
          commitAddNotification(context, loadingNotification);
          const response = (await Promise.all([
              api.extendItem(context.state.token, payload),
              await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
          ]))[0];
          commitSetItemExtend(context, response.data);
          commitRemoveNotification(context, loadingNotification);
          commitAddNotification(context, { content: '續約成功！', color: 'success' });
          router.push('/dashboard');
      } catch (error) {
          if (error.response!.status === 400) {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Bad request', color: 'error' });
          } else if (error.response!.data.detail === "Incorrect OTP public key.") {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Google 驗證碼錯誤', color: 'error' });
          } else if (error.response!.status === 422) {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Invalid', color: 'error' });
          } else {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: error.response!.data.detail, color: 'error' });
          }
          await dispatchCheckApiError(context, error);
      }
    },
    async actionItemProposeWithdrawal(context: MainContext, payload: {itemId: number, otp_key: string}) {
        const loadingNotification = { content: '申請提領中', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.proposeWithdrawalItem(context.state.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetItemProposeWithdrawal(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '申請提領成功！', color: 'success' });
            router.push('/dashboard');
        } catch (error) {
            if (error.response!.status === 400) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Bad request', color: 'error' });
            } else if (error.response!.data.detail === "Incorrect OTP public key.") {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Google 驗證碼錯誤', color: 'error' });
            } else if (error.response!.status === 422) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Invalid', color: 'error' });
            }
            await dispatchCheckApiError(context, error);
        }
    },
    async actionProposeWalletWithdrawal(context: MainContext, payload: {token: string, amount: number, chain: string, address: string, otp_key: string}) {
        const loadingNotification = { content: '申請錢包提領中', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.proposeWalletWithdrawal(context.state.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetWalletProposeWithdrawal(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '申請錢包提領成功！', color: 'success' });
            router.push('/wallet');
            await dispatchGetUserWallet(context);
        } catch (error) {
            if (error.response!.status === 400) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Bad request', color: 'error' });
            } else if (error.response!.data.detail === "Incorrect OTP public key.") {
              commitRemoveNotification(context, loadingNotification);
              commitAddNotification(context, { content: 'Google 驗證碼錯誤', color: 'error' });
            } else if (error.response!.status === 422) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Invalid', color: 'error' });
            }
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                commitSetUserProfile(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUserWallet(context: MainContext) {
      try {
          const response = await api.getWallet(context.state.token);
          if (response.data) {
              commitSetUserWallet(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetUserTransactions(context: MainContext) {
        try {
            const response = await api.getUserTransactions(context.state.token);
            if (response.data) {
                commitSetUserTransactions(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUserInwardTransactions(context: MainContext) {
      try {
          const response = await api.getUserInwardTransactions(context.state.token);
          if (response.data) {
              commitSetUserInwardTransactions(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetUserOutwardTransactions(context: MainContext) {
      try {
          const response = await api.getUserOutwardTransactions(context.state.token);
          if (response.data) {
              commitSetUserOutwardTransactions(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetUserWhitelistAddresses(context: MainContext) {
      try {
        const response = await api.getUserWhitelistAddresses(context.state.token);
        if (response.data) {
            commitSetUserWhitelistAddresses(context, response.data);
        }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionCreateUserWhitelistAddresses(context: MainContext, payload: {name: string, address: string}) {
      try {
        const response = await api.createUserWhitelistAddress(context.state.token, payload);
        if (response.data) {
            commitSetUserWhitelistAddresses(context, response.data);
            commitAddNotification(context, { content: '地址新增成功！', color: 'success' });
            await dispatchGetUserWhitelistAddresses(context);
        }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionUpdateUserWhitelistAddresses(context: MainContext, payload: {address_id: number, name: string, address: string}) {
      try {
        const response = await api.updateUserWhitelistAddress(context.state.token, payload);
        if (response.data) {
            commitSetUserWhitelistAddresses(context, response.data);
            commitAddNotification(context, { content: '地址更新成功！', color: 'success' });
            await dispatchGetUserWhitelistAddresses(context);
        }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionDeleteUserWhitelistAddresses(context: MainContext, payload: {address_id: number}) {
      try {
        const response = await api.deleteUserWhitelistAddress(context.state.token, payload);
        if (response.data) {
            commitSetUserWhitelistAddresses(context, response.data);
            commitAddNotification(context, { content: '地址刪除成功！', color: 'success' });
            await dispatchGetUserWhitelistAddresses(context);
        }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetPositions(context: MainContext) {
      try {
          const response = await api.getPositions(context.state.token);
          if (response.data) {
              commitSetPositions(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
  },    
    async actionGetUserItems(context: MainContext) {
        try {
            const response = await api.getItems(context.state.token);
            if (response) {
                commitSetUserItems(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUserItemsOngoing(context: MainContext) {
      try {
          const response = await api.getItemsOngoing(context.state.token);
          if (response) {
              commitSetUserItemsOngoing(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetUserItemsPending(context: MainContext) {
      try {
          const response = await api.getItemsPending(context.state.token);
          if (response) {
              commitSetUserItemsPending(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetUserItemsEnded(context: MainContext) {
      try {
          const response = await api.getItemsEnded(context.state.token);
          if (response) {
              commitSetUserItemsEnded(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetUserItemDetail(context: MainContext, payload: { itemId: number}) {
      try {
          const response = await api.getItemDetail(context.state.token, payload.itemId);
          if (response) {
              commitSetUserItemDetail(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetConstants(context: MainContext) {
        try {
            const response = await api.getConstants(context.state.token);
            if (response) {
                commitSetConstants(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetGraphData(context: MainContext, payload: { plan_name: string}) {
      try {
          const response = await api.getGraphData(context.state.token, payload.plan_name);
          if (response) {
              commitSetGraphData(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionGetInvitedUsers(context: MainContext) {
      try {
          const response = await api.getInvitedUsers(context.state.token);
          if (response) {
              commitSetInvitedUsers(context, response.data);
          }
      } catch (error) {
          await dispatchCheckApiError(context, error);
      }
    },
    async actionUpdateUserProfile(context: MainContext, payload) {
        const loadingNotification = { content: '修改中', showProgress: true };  
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMe(context.state.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserProfile(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: '修改成功', color: 'success' });
        } catch (error) {
            if (error.response!.data.detail === "Incorrect OTP public key.") {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Google 驗證碼錯誤', color: 'error' });
            } 
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitAddNotification(context, { content: 'Logged out', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/home');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 401) {
            await dispatchLogOut(context);
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/home') {
            router.push('/home');
        }
    },
    async removeNotification(context: MainContext, payload: { notification: AppNotification, timeout: number }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commitRemoveNotification(context, payload.notification);
                resolve(true);
            }, payload.timeout);
        });
    },
    async passwordRecovery(context: MainContext, payload: { username: string }) {
        const loadingNotification = { content: 'Sending password recovery email', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.passwordRecovery(payload.username),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password recovery email sent', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Incorrect username' });
        }
    },
    async resetPassword(context: MainContext, payload: { password: string, token: string }) {
        const loadingNotification = { content: 'Resetting password', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.resetPassword(payload.password, payload.token),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password successfully reset', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Error resetting password' });
        }
    },
    async verifyEmail(context: MainContext, payload: { token: string }) {
        const loadingNotification = { content: 'Verifying Email', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.verifyEmail(payload.token),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Email 成功驗證！', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            if (error.response!.data.detail === "Invalid token") {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: '連結錯誤或過期', color: 'error' });
            } else if (error.response!.data.detail === "User already activated.") {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'User already activated.', color: 'info' });
            } else {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: error.response!.status + ' Error', color: 'error' });
            }
        }
    },
    // async getVerificationEmail(context: MainContext, payload: { email: string }) {
    //     const loadingNotification = { content: 'Verifying Email', showProgress: true };
    //     try {
    //         commitAddNotification(context, loadingNotification);
    //         const response = (await Promise.all([
    //             api.verifyEmail(payload.token),
    //             await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
    //         ]))[0];
    //         commitRemoveNotification(context, loadingNotification);
    //         commitAddNotification(context, { content: 'Password successfully reset', color: 'success' });
    //         await dispatchLogOut(context);
    //     } catch (error) {
    //         if (error.response!.data.detail === "Invalid token") {
    //             commitRemoveNotification(context, loadingNotification);
    //             commitAddNotification(context, { content: '連結錯誤或過期', color: 'error' });
    //         } else if (error.response!.data.detail === "User already activated.") {
    //             commitRemoveNotification(context, loadingNotification);
    //             commitAddNotification(context, { content: 'Email 驗證已通過', color: 'info' });
    //         } else {
    //             commitRemoveNotification(context, loadingNotification);
    //             commitAddNotification(context, { content: error.response!.status + ' Error', color: 'error' });
    //         }
    //     }
    // },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchGetUserWallet = dispatch(actions.actionGetUserWallet);
export const dispatchGetUserTransactions = dispatch(actions.actionGetUserTransactions);
export const dispatchGetUserInwardTransactions = dispatch(actions.actionGetUserInwardTransactions);
export const dispatchGetUserOutwardTransactions = dispatch(actions.actionGetUserOutwardTransactions);
export const dispatchGetUserWhitelistAddresses = dispatch(actions.actionGetUserWhitelistAddresses);
export const dispatchCreateUserWhitelistAddresses = dispatch(actions.actionCreateUserWhitelistAddresses);
export const dispatchUpdateUserWhitelistAddresses = dispatch(actions.actionUpdateUserWhitelistAddresses);
export const dispatchDeleteUserWhitelistAddresses = dispatch(actions.actionDeleteUserWhitelistAddresses);
export const dispatchGetPositions = dispatch(actions.actionGetPositions);
export const dispatchGetUserItems = dispatch(actions.actionGetUserItems);
export const dispatchGetUserItemsOngoing = dispatch(actions.actionGetUserItemsOngoing);
export const dispatchGetUserItemsPending = dispatch(actions.actionGetUserItemsPending);
export const dispatchGetUserItemsEnded = dispatch(actions.actionGetUserItemsEnded);
export const dispatchGetUserItemDetail = dispatch(actions.actionGetUserItemDetail);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchVerifyEmail = dispatch(actions.verifyEmail);
// export const dispatchGetVerificationEmail = dispatch(actions.getVerificationEmail);
export const dispatchUserCreate = dispatch(actions.actionUserCreate);
export const dispatchSendKYC = dispatch(actions.actionSendKYC);
export const dispatchGenerateOtp = dispatch(actions.actionGenerateOtp);
export const dispatchGetOtpQrcode = dispatch(actions.actionGetOtpQrcode);
export const dispatchGetOtpInfo = dispatch(actions.actionGetOtpInfo);
export const dispatchSetupOTP = dispatch(actions.actionSetupOTP);
export const dispatchItemCreate = dispatch(actions.actionItemCreate);
export const dispatchItemAddressUpdate = dispatch(actions.actionItemAddressUpdate);
export const dispatchItemCancelPurchase = dispatch(actions.actionItemCancelPurchase);
export const dispatchItemExtend = dispatch(actions.actionItemExtend);
export const dispatchItemProposeWithdrawal = dispatch(actions.actionItemProposeWithdrawal);
export const dispatchProposeWalletWithdrawal = dispatch(actions.actionProposeWalletWithdrawal);
export const dispatchGetConstants = dispatch(actions.actionGetConstants);
export const dispatchGetGraphData = dispatch(actions.actionGetGraphData);
export const dispatchGetInvitedUsers = dispatch(actions.actionGetInvitedUsers);
