import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MainState } from './state';

const defaultState: MainState = {
  isLoggedIn: null,
  token: '',
  logInError: false,
  userCreateError: false,
  itemCreateError: false,
  itemAddressUpdateError: false,
  userProfile: null,
  userWallet: null,
  userTransactions: [],
  userInwardTransactions: [],
  userOutwardTransactions: [],
  userWhitelistAddresses: [],
  positions: [],
  sendKYC: null,
  generateOtp: null,
  otpQrcode: null,
  otpInfo: null,
  setupOtp: null,
  userCreate: null,
  itemCreate: null,
  itemAddressUpdate: null,
  itemCancelPurhcase: null,
  itemExtend: null,
  itemProposeWithdrawal: null,
  walletProposeWithdrawal: null,
  userItems: [],
  userItemsOngoing: [],
  userItemsPending: [],
  userItemsEnded: [],
  userItemDetail: null,
  constants: null,
  graphData: null,
  invitedUser: [],
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
