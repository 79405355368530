import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserWallet, IUserItem, IUserProfileUpdate, IUserProfileCreate, IUserCreate, IItemCreate, IConstants, IGraphData, IInvitedUser, IUserItemDetail, IItemAddressUpdate, IItemCancelPurchase, IItemExtend, IItemProposeWithdrawal, IUserKYC, IOtp, IOtpQrcode, IOtpInfo, IOtpKey, IUserTransaction, IProposeWalletWithdrawal, IPosition, IUserWhitelistAddress } from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    // params.append('otp_key', otp_key);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async getWallet(token: string) {
    return axios.get<IUserWallet>(`${apiUrl}/api/v1/transactions/my_wallet`, authHeaders(token));
  },
  async getUserTransactions(token: string) {
    return axios.get<IUserTransaction[]>(`${apiUrl}/api/v1/transactions/`, authHeaders(token));
  },
  async getUserInwardTransactions(token: string) {
    return axios.get<IUserTransaction[]>(`${apiUrl}/api/v1/transactions/in`, authHeaders(token));
  },
  async getUserOutwardTransactions(token: string) {
    return axios.get<IUserTransaction[]>(`${apiUrl}/api/v1/transactions/out`, authHeaders(token));
  },
  async getUserWhitelistAddresses(token: string) {
    return axios.get<IUserWhitelistAddress[]>(`${apiUrl}/api/v1/users/whitelist_addresses_me`, authHeaders(token));
  },
  async createUserWhitelistAddress(token: string, data: {name: string, address: string}) {
    return axios.post<IUserWhitelistAddress[]>(`${apiUrl}/api/v1/users/whitelist_addresses_me`, data, authHeaders(token));
  },
  async updateUserWhitelistAddress(token: string, data: {address_id: number, name: string, address: string}) {
    return axios.put<IUserWhitelistAddress[]>(`${apiUrl}/api/v1/users/whitelist_addresses_me`, data, authHeaders(token));
  },
  async deleteUserWhitelistAddress(token: string, data: {address_id: number}) {
    return axios.post<IUserWhitelistAddress[]>(`${apiUrl}/api/v1/users/delete_whitelist_addresses_me`, data, authHeaders(token));
  },
  async getPositions(token: string) {
    return axios.get<IPosition[]>(`${apiUrl}/api/v1/positions/active`, authHeaders(token));
  },
  async getItems(token: string) {
    return axios.get<IUserItem[]>(`${apiUrl}/api/v1/items/`, authHeaders(token));
  },
  async getItemsOngoing(token: string) {
    return axios.get<IUserItem[]>(`${apiUrl}/api/v1/items/ongoing`, authHeaders(token));
  },
  async getItemsPending(token: string) {
    return axios.get<IUserItem[]>(`${apiUrl}/api/v1/items/pending`, authHeaders(token));
  },
  async getItemsEnded(token: string) {
    return axios.get<IUserItem[]>(`${apiUrl}/api/v1/items/ended`, authHeaders(token));
  },
  async getItemDetail(token: string, itemId: number) {
    return axios.get<IUserItemDetail>(`${apiUrl}/api/v1/items/detail/${itemId}`, authHeaders(token));
  },
  async getConstants(token: string) {
    return axios.get<IConstants>(`${apiUrl}/api/v1/constants/`, authHeaders(token));
  },
  async getGraphData(token: string, plan_name: string) {
    // let planName = "APR_USDT_stable";
    return axios.get<IGraphData>(`${apiUrl}/api/v1/historys/graph/${plan_name}`, authHeaders(token));
  },
  async getInvitedUsers(token: string) {
    // let planName = "APR_USDT_stable";
    return axios.get<IInvitedUser[]>(`${apiUrl}/api/v1/users/invited`, authHeaders(token));
  },
  async createItem(token: string, data: IItemCreate) {
    return axios.post(`${apiUrl}/api/v1/items/`, data, authHeaders(token));
  },
  async updateItemAddress(token: string, data: IItemAddressUpdate) {
    return axios.put(`${apiUrl}/api/v1/items/change_address`, data, authHeaders(token));
  },
  async cancelItemPurchase(token: string, data: IItemCancelPurchase) {
    return axios.post(`${apiUrl}/api/v1/items/cancel_purchase/${data.itemId}`, data, authHeaders(token));
  },
  async extendItem(token: string, data: IItemExtend) {
    return axios.put(`${apiUrl}/api/v1/items/extend`, data, authHeaders(token));
  },
  async proposeWithdrawalItem(token: string, data: IItemProposeWithdrawal) {
    return axios.put(`${apiUrl}/api/v1/items/propose_withdrawal`, data, authHeaders(token));
  },
  async proposeWalletWithdrawal(token: string, data: IProposeWalletWithdrawal) {
    return axios.post(`${apiUrl}/api/v1/transactions/propose_withdrawal`, data, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async sendKYC(token: string, data: IUserKYC) {
    return axios.post(`${apiUrl}/api/v1/users/start-kyc`, data, authHeaders(token));
  },
  async generateOtp(token: string) {
    return axios.get<IOtp>(`${apiUrl}/api/v1/otp/generate`, authHeaders(token));
  },
  async getOtpQrcode(token: string) {
    return axios.get<IOtpQrcode>(`${apiUrl}/api/v1/otp/get_otp_qrcode`, authHeaders(token));
  },
  async getOtpInfo(token: string) {
    return axios.get<IOtpInfo>(`${apiUrl}/api/v1/otp/otp_info`, authHeaders(token));
  },
  async setupOtp(token: string, data: IOtpKey) {
    return axios.post(`${apiUrl}/api/v1/otp/setup`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async createUserOpen(data: IUserCreate) {
    return axios.post(`${apiUrl}/api/v1/users/open`, data);
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async verifyEmail(token: string) {
    return axios.post(`${apiUrl}/api/v1/users/verify-email/`, {token: token});
  },
};
