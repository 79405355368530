import Vue from 'vue';

Vue.filter('isUSDT', function(value) {
  if (value.startsWith('USDT')) {
    return true;
  } else {
    return false;
  }
});

Vue.filter('parseTransactionStatus', function(v) {
    if (!v) {
      return v;
    } else if (v === 'proposed_withdrawal') {
      return '錢包提幣處理中';
    } else if (v === 'confirmed_withdrawal') {
      return '錢包提幣完成';
    } else if (v === 'wallet_to_plan') {
      return '從錢包申購方案';
    } else if (v === 'plan_withdrawal') {
      return '方案結束';
    } else if (v === 'plan_deposit') {
      return '方案付款';
    } else if (v === 'cancel_plan') {
      return '取消申購';
    } else if (v === 'airdrop') {
      return '空投';
    } else if (v === 'airdrop_paper_money') {
      return '體驗金' ;
    }else if (v === 'wallet_deposit') {
      return '錢包入金';
    } else if (v === 'plan_terminated') {
      return '方案中途解約';
    } else {
      return v;
    }
});

Vue.filter('transactionExplorer', function(txID, chain) {
  if (chain == 'BEP20') {
    return "https://www.bscscan.com/tx/" + txID;
  } else if (chain == 'Polygon') {
    return "https://www.polygonscan.com/tx/" + txID;
  } else if (chain == 'TRC20') {
    return "https://tronscan.org/#/transaction/" + txID;
  } else if (chain == 'ERC20') {
    return "https://www.etherscan.io/tx/" + txID;
  } else {
    return txID;
  }
});

Vue.filter('parseTitle', function(value) {
    if (!value) {
      return value;
    } else if (value === 'TWD_stable') {
      return '新臺幣穩定方案';
    } else if (value === 'USDT_fixed') {
      return 'USDⓢ 小資方案';
    } else if (value === 'USDT_stable') {
      return 'USDⓢ 穩定方案';
    } else if (value === 'USDT_stable_org') {
      return 'USDⓢ 穩定機構方案';
    } else if (value === 'TWD_newbie') {
      return '新臺幣新手方案';
    } else if (value === 'USDT_high') {
      return 'USDⓢ 高利率方案';
    } else if (value === 'USDT_quant') {
      return 'USDⓢ 量化交易方案';
    } else if (value === 'USDT_active') {
      return 'USDⓢ 鯨魚方案'
    } else if (value === 'USDD_stable') {
      return 'USDD 挖礦方案'
    } else if (value === 'USDT_star') {
      return 'USDⓢ 星空方案'
    } else if (value === 'USDT_sun') {
      return 'USDⓢ 朝日方案';
    } else if (value === 'USDT_meteor') {
      return 'USDⓢ 流星方案';
    } else if (value === 'USDT_comet') {
      return 'USDⓢ 彗星方案';
    } else if (value === 'USDT_blue_whale') {
      return 'USDⓢ 藍鯨方案';
    } else if (value === 'ETH_stable') {
      return 'ETH 穩定方案'
    } else if (value === 'BTC_stable') {
      return 'BTC 穩定方案'
    } else if (value === 'BNB_stable') {
      return 'BNB 穩定方案'
    } else {
      return value;
    }
});

Vue.filter('toCurrency', function(value, c) {
    if (typeof value !== 'number') {
        return value;
    }

    let decimal_points = 0
    if (c == 'USDT' || c == 'USDC' || c == 'BUSD') {
      decimal_points = 2
    } else if (c == 'BTC') {
      decimal_points = 6
    } else if (c == 'ETH') {
      decimal_points = 5
    } else if (c == 'BNB') {
      decimal_points = 4
    }

    value = Math.floor(value * Math.pow(10, decimal_points)) / Math.pow(10, decimal_points);

    if (c === 'None') {
      const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD', // 'TWD', 'USD'
          minimumFractionDigits: 0,
          maximumFractionDigits: decimal_points,
      });
      return formatter.format(value).replace('$', '');
    } 

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD', // 'TWD', 'USD'
        minimumFractionDigits: 0,
        maximumFractionDigits: decimal_points,
    });

    if (c != 'NTD') {
      return formatter.format(value).replace('$', '');
    } else {
      return formatter.format(value);
    }
});

Vue.filter('toPercentage', function(value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = value * 100;
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  value = value + ' %';
  return value;
});

Vue.filter('toAPYPercentage', function(value, decimals, compound_time=4) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  // to APY
  value = (value/compound_time + 1)**compound_time - 1;

  value = value * 100;
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  value = value + ' %';
  return value;
});

// Vue.filter('toAPYPercentage26', function(value, decimals) {
//   if (!value) {
//     value = 0;
//   }

//   if (!decimals) {
//     decimals = 0;
//   }

//   // to APY
//   value = (value/26 + 1)**26 - 1;

//   value = value * 100;
//   value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
//   value = value + ' %';
//   return value;
// });

Vue.filter('floor', function(value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});

Vue.filter('round', function(value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});

Vue.filter('gains', function(value, c) {
  if (!value) {
    value = 0;
  }

  let decimal_points = 0
  if (c == 'USDT' || c == 'USDC' || c == 'BUSD') {
    decimal_points = 2
  } else if (c == 'BTC') {
    decimal_points = 6
  } else if (c == 'ETH') {
    decimal_points = 5
  } else if (c == 'BNB') {
    decimal_points = 4
  }   

  value = Math.floor(value * Math.pow(10, decimal_points)) / Math.pow(10, decimal_points);

  if (value > 0) {
    value = '+ ' + value;
  }
  return value;
});

Vue.filter('parseTimestamp', function(value) {
  if (!value) {
    return '無紀錄';
  }

  var date = new Date(value);
  // This data object is generated by client (therefore uses client's time zone)

  return date.getFullYear() + '/' + (date.getMonth()+1) + '/' + date.getDate()+ ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0");
});

Vue.filter('parseStatus', function(value) {
  if (!value) {
    return value;
  } else if (value === 'not_paid') {
    return '等待付款中';
  } else if (value === 'paid') {
    return '付款完成，準備啟動中';
  } else if (value === 'ongoing') {
    return '累積收益中';
  } else if (value === 'ended') {
    return '方案結束';
  } else if (value === 'withdrawed') {
    return '方案結束，已提領';
  } else if (value === 'renewed') {
    return '方案結束，已續約';
  } else if (value === 'terminated') {
    return '已中途解約';
  }
  else {
    return value;
  }
});

Vue.filter('parseDuration', function(value) {
  if (!value) {
    return value;
  } else {
    return value + ' 日';
  }
});

Vue.filter('parseRemainingDuration', function(value, timeEnded) {
  var time1 = new Date(timeEnded);
  var now = new Date();
  
  var remainingMs = time1.getTime() - now.getTime();
  var remainingDay = Math.round(remainingMs / 1000 / 60 / 1440);

  if (!value) {
    return value;
  } else if (remainingDay < 0) {
    return '已結束';
  } else {
    return remainingDay + ' 日';
  }
});

Vue.filter('parseWantEmail', function(v) {
  if (v===true || v===null) {
    return '開啟';
  } else {
    return '關閉';
  }
});

Vue.filter('parseOtpSwitch', function(v) {
  if (v===false || v===null) {
    return '尚未設定';
  } else {
    return '已開啟';
  }
});

Vue.filter('parseBonusAmount', function(v) {
  if (v === null || v == 0) {
    return '尚未完成';
  } else if (v < 0) {
    return '有 ' + -v + ' USDT 尚未領取' 
  } else {
    return '已領取 ' + v + ' USDT'
  }
});

Vue.filter('parseInviteBonusAmount', function(v) {
  if (v === null || v == 0) {
    return 0 + ' USDT';
  } else {
    var decimals = 6;
    var value = Math.floor(v * Math.pow(10, decimals)) / Math.pow(10, decimals);

    return value + ' USDT';
  }
});

Vue.filter('parseInviteURL', function(v) {
  return 'https://lepus.fi/createUser?c=' + v
});

Vue.filter('parseTimestamp', function(value) {
  if (!value) {
    return '無紀錄';
  }

  var date = new Date(value);
  // This data object is generated by client (therefore uses client's time zone)

  return date.getFullYear() + '/' + (date.getMonth()+1) + '/' + date.getDate()+ ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0");
});


Vue.filter('toAPY', function(value, decimals, compound_time=4) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  // to APY
  value = (value/compound_time + 1)**compound_time - 1;

  value = value * 100;
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  value = value;
  return value;
});

// Vue.filter('toAPY26', function(value, decimals) {
//   if (!value) {
//     value = 0;
//   }

//   if (!decimals) {
//     decimals = 0;
//   }

//   // to APY
//   value = (value/26 + 1)**26 - 1;

//   value = value * 100;
//   value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
//   value = value;
//   return value;
// });

Vue.filter('toCurrencyNoDemical', function(value, c) {
    if (typeof value !== 'number') {
        return value;
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: c, // 'TWD', USD
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    if (c === 'USD') {
      return formatter.format(value).replace('$', '') + ' USDⓢ';
    } else {
      return formatter.format(value);
    }
});

Vue.filter('toCurrencyLong', function(value, c) {
  if (typeof value !== 'number') {
      return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: c, // 'TWD', USD
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
  });
  if (c === 'USD') {
    return formatter.format(value).replace('$', '') + ' USDT';
  } else {
    return formatter.format(value);
  }
});

Vue.filter('parseWantEmail', function(v) {
  if(v===true || v===null) {
    return '開啟';
  } else {
    return '關閉';
  }
});

Vue.filter('parseUsedInviteCode', function(v) {
  if(v===null) {
    return '未使用';
  } else {
    return v;
  }
});


Vue.filter('parseAddress', function(v) {
  if(v===null) {
    return '尚未設定';
  } else {
    return v;
  }
});

Vue.filter('parseUserLevel', function(v) {
  return 'Level ' + v;
});

Vue.filter('parseKYC', function(flag, ing) {
  if (flag===true) {
    return '已通過';
  } else if (flag!=true && ing==true) {
    return '審核中'
  } else {
    return '未通過';
  }
});

Vue.filter('null2zero', function(v) {
  if(v===null) {
    return 0;
  } else {
    return v;
  }
});

Vue.filter('parseCodePromotion', function(v) {
  if(v==='null') {
    return '未使用';
  } else {
    return v;
  }
});
