import { IUserProfile, IUserWallet, IUserItem, IUserCreate, IItemCreate, IConstants, IGraphData, IInvitedUser, IUserItemDetail, IItemAddressUpdate, IItemCancelPurchase, IItemExtend, IItemProposeWithdrawal, IUserKYC, IOtp, IOtpQrcode, IOtpInfo, IOtpKey, IUserTransaction, IProposeWalletWithdrawal, IPosition, IUserWhitelistAddress } from '@/interfaces';
import { MainState, AppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload;
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload;
    },
    setUserWallet(state: MainState, payload: IUserWallet) {
        state.userWallet = payload;
    },
    setUserTransactions(state: MainState, payload: IUserTransaction[]) {
        state.userTransactions = payload;
    },
    setUserInwardTransactions(state: MainState, payload: IUserTransaction[]) {
      state.userInwardTransactions = payload;
    },
    setUserOutwardTransactions(state: MainState, payload: IUserTransaction[]) {
      state.userOutwardTransactions = payload;
    },
    setUserWhitelistAddresses(state: MainState, payload: IUserWhitelistAddress[]) {
      state.userWhitelistAddresses = payload;
    },
    setPositions(state: MainState, payload: IPosition[]) {
      state.positions = payload;
    },    
    setUserItems(state: MainState, payload: IUserItem[]) {
        state.userItems = payload;
    },
    setUserItemsOngoing(state: MainState, payload: IUserItem[]) {
      state.userItemsOngoing = payload;
    },
    setUserItemsPending(state: MainState, payload: IUserItem[]) {
      state.userItemsPending = payload;
    },
    setUserItemsEnded(state: MainState, payload: IUserItem[]) {
      state.userItemsEnded = payload;
    },
    setUserItemDetail(state: MainState, payload: IUserItemDetail) {
      state.userItemDetail = payload;
    },
    setDashboardMiniDrawer(state: MainState, payload: boolean) {
        state.dashboardMiniDrawer = payload;
    },
    setDashboardShowDrawer(state: MainState, payload: boolean) {
        state.dashboardShowDrawer = payload;
    },
    addNotification(state: MainState, payload: AppNotification) {
        state.notifications.push(payload);
    },
    removeNotification(state: MainState, payload: AppNotification) {
        state.notifications = state.notifications.filter((notification) => notification !== payload);
    },
    setUserCreate(state: MainState, payload: IUserCreate) {
        state.userCreate = payload;
    },
    setSendKYC(state: MainState, payload: IUserKYC) {
        state.sendKYC = payload;
    },
    setGenerateOtp(state: MainState, payload: IOtp) {
        state.generateOtp = payload;
    },
    setOtpQrcode(state: MainState, payload: IOtpQrcode) {
        state.otpQrcode = payload;
    },
    setOtpInfo(state: MainState, payload: IOtpInfo) {
        state.otpInfo = payload;
    },
    setSetupOtp(state: MainState, payload: IOtpKey) {
        state.setupOtp = payload;
    },
    setItemCreate(state: MainState, payload: IItemCreate) {
        state.itemCreate = payload;
    },
    setItemAddressUpdate(state: MainState, payload: IItemAddressUpdate) {
        state.itemAddressUpdate = payload;
    },
    setItemCancelPurchase(state: MainState, payload: IItemCancelPurchase) {
        state.itemCancelPurhcase = payload;
    },
    setItemExtend(state: MainState, payload: IItemExtend) {
        state.itemExtend = payload;
    },
    setItemProposeWithdrawal(state: MainState, payload: IItemProposeWithdrawal){
        state.itemProposeWithdrawal = payload;
    },
    setWalletProposeWithdrawal(state: MainState, payload: IProposeWalletWithdrawal){
        state.walletProposeWithdrawal = payload;
    },
    setConstants(state: MainState, payload: IConstants) {
        state.constants = payload;
    },
    setGraphData(state: MainState, payload: IGraphData) {
        state.graphData = payload;
    },
    setInvitedUsers(state: MainState, payload: IInvitedUser[]) {
        state.invitedUser = payload;
  },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitSetUserWallet = commit(mutations.setUserWallet);
export const commitSetUserTransactions = commit(mutations.setUserTransactions);
export const commitSetUserInwardTransactions = commit(mutations.setUserInwardTransactions);
export const commitSetUserOutwardTransactions = commit(mutations.setUserOutwardTransactions);
export const commitSetUserWhitelistAddresses = commit(mutations.setUserWhitelistAddresses);
export const commitSetPositions = commit(mutations.setPositions);
export const commitSetUserItems = commit(mutations.setUserItems);
export const commitSetUserItemsOngoing = commit(mutations.setUserItemsOngoing);
export const commitSetUserItemsPending = commit(mutations.setUserItemsPending);
export const commitSetUserItemsEnded = commit(mutations.setUserItemsEnded);
export const commitSetUserItemDetail = commit(mutations.setUserItemDetail);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetUserCreate = commit(mutations.setUserCreate);
export const commitSendKYC = commit(mutations.setSendKYC);
export const commitGenerateOtp = commit(mutations.setGenerateOtp);
export const commitSetOtpQrcode = commit(mutations.setOtpQrcode);
export const commitSetOtpInfo = commit(mutations.setOtpInfo);
export const commitSetupOTP = commit(mutations.setSetupOtp);
export const commitSetItemCreate = commit(mutations.setItemCreate);
export const commitSetItemAddressUpdate = commit(mutations.setItemAddressUpdate);
export const commitSetItemCancelPurchase = commit(mutations.setItemCancelPurchase);
export const commitSetItemExtend = commit(mutations.setItemExtend);
export const commitSetItemProposeWithdrawal = commit(mutations.setItemProposeWithdrawal);
export const commitSetWalletProposeWithdrawal = commit(mutations.setWalletProposeWithdrawal);
export const commitSetConstants = commit(mutations.setConstants);
export const commitSetGraphData = commit(mutations.setGraphData);
export const commitSetInvitedUsers = commit(mutations.setInvitedUsers);
