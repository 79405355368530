import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    hasAdminAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_superuser && state.userProfile.is_active);
    },
    loginError: (state: MainState) => state.logInError,
    userCreateError: (state: MainState) => state.userCreateError,
    itemCreateError: (state: MainState) => state.itemCreateError,
    itemAddressUpdateError: (state: MainState) => state.itemCreateError,
    dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
    dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
    userProfile: (state: MainState) => state.userProfile,
    userWallet: (state: MainState) => state.userWallet,
    userTransactions: (state: MainState) => state.userTransactions,
    userInwardTransactions: (state: MainState) => state.userInwardTransactions,
    userOutwardTransactions: (state: MainState) => state.userOutwardTransactions,
    userWhitelistAddresses: (state: MainState) => state.userWhitelistAddresses,
    positions: (state: MainState) => state.positions,
    userItems: (state: MainState) => state.userItems,
    userItemsOngoing: (state: MainState) => state.userItemsOngoing,
    userItemsPending: (state: MainState) => state.userItemsPending,
    userItemsEnded: (state: MainState) => state.userItemsEnded,
    userItemDetail: (state: MainState) => state.userItemDetail,
    otpInfo: (state: MainState) => state.otpInfo,
    otpQrcode: (state: MainState) => state.otpQrcode,
    constants: (state: MainState) => state.constants,
    graphData: (state: MainState) => state.graphData,
    invitedUsers: (state: MainState) => state.invitedUser,
    token: (state: MainState) => state.token,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    firstNotification: (state: MainState) => state.notifications.length > 0 && state.notifications[0],
};

const {read} = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readUserCreateError = read(getters.userCreateError);
export const readItemCreateError = read(getters.itemCreateError);
export const readItemAddressUpdateError = read(getters.itemAddressUpdateError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readUserWallet = read(getters.userWallet);
export const readUserTransactions = read(getters.userTransactions);
export const readUserInwardTransactions = read(getters.userInwardTransactions);
export const readUserOutwardTransactions = read(getters.userOutwardTransactions);
export const readUserWhitelistAddresses = read(getters.userWhitelistAddresses);
export const readPositions = read(getters.positions);
export const readUserItems = read(getters.userItems);
export const readUserItemsOngoing = read(getters.userItemsOngoing);
export const readUserItemsPending = read(getters.userItemsPending);
export const readUserItemsEnded = read(getters.userItemsEnded);
export const readUserItemDetail = read(getters.userItemDetail);
export const readOtpInfo = read(getters.otpInfo);
export const readOtpQrcode = read(getters.otpQrcode);
export const readConstants = read(getters.constants);
export const readGraphData = read(getters.graphData);
export const readInvitedUsers = read(getters.invitedUsers);
export const readFirstNotification = read(getters.firstNotification);
